import { Box, Container, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useGetLogo from "src/components/logo/hooks/use-logo";

const AuthLayout = ({ children }) => {
  const logo = useGetLogo();
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        background: palette.background.main,
        py: "150px",
      }}
    >
      <Container
        fixed
        maxWidth="sm"
        sx={{
          height: "100%",
          textAlign: "center",
        }}
      >
        <Paper variant="outlined" sx={{ padding: 3 }}>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              justifyContent: "center",
            }}
          >
            <img style={{ width: "50%", height: "auto" }} src={logo} />
          </Box>
          {children}
        </Paper>
      </Container>
    </Box>
  );
};

export default AuthLayout;
